import React from 'react';
import Typed from 'react-typed';
import { LayoutBlock, Container, Row, Detail, Column, Section, Title } from '@dragonchain-dev/dragon-kit';

import PageLayout from '../components/PageLayout';
import { EternalForm } from '../components/_index';

import EternalNIcon from '../assets/icons/eternal-n.svg';
import LockIcon from '../assets/icons/lock.svg';
import ReportIcon from '../assets/icons/report.svg';
import ShieldIcon from '../assets/icons/shield.svg';
import BlockIcon from '../assets/icons/block.svg';
import BubbleIcon from '../assets/icons/bubble.svg';

const Home = () => {
  return (
    <PageLayout>
      <LayoutBlock>
        <Container>
          <Row gap="large">
            <Column span={12}>
              <div className="eternal-hero">
                <Title>
                  <div className="text">Eternal is your portal to archive&nbsp;</div>
                  <Typed
                    className="word"
                    strings={['tweets', 'text', 'stories', 'anything']}
                    typeSpeed={100}
                    backSpeed={80}
                    smartBackspace
                    backDelay={1500}
                  />
                </Title>
                <EternalForm />
                <div className="note">The content will be added to Eternal&apos;s blockchain forever.</div>
              </div>
            </Column>
          </Row>
          <Row gap="large">
            <Column span={9}>
              <Section title="An immutable copy of any type of content, stored on Dragonchain, and interchained with multiple public blockchain networks.">
                Each type of content captured through Eternal becomes an immutable Dragonchain transaction,
                interchained with Bitcoin, Ethereum, and Ethereum Classic.
              </Section>
            </Column>
          </Row>
          <Row auto gap="large" columnGap="medium" span={4} laptopTabletSpan={6}>
            <Column>
              <Detail icon={<EternalNIcon />} title="About Eternal">
                Harness the immutable power of Eternal to capture, archive, sign, and timestamp anything you
                want to preserve. Everything going through Eternal is stored on Dragonchain before being
                hashed with the combined network security and measurable proof of multiple public networks.
              </Detail>
            </Column>
            <Column>
              <Detail icon={<LockIcon />} title="Security Value">
                A recent update to Eternal adds an exciting new visualized metric. Transaction Security Value
                shows, in real time, how many dollars ($USD) of energy consumption has been used to secure a
                transaction. Starting in the millions of dollars, the value continually increases over time,
                at no added costs for users.
              </Detail>
            </Column>
            <Column>
              <Detail icon={<ReportIcon />} title="Proof Reports">
                Download any Proof Report with the full history and breakdown of any Eternal transaction. Not
                unlike a block explorer, the report provides information essential to proving chain of custody
                by showing the transaction ID, timestamp, raw data, and the hash of Ethereum or Bitcoin.
              </Detail>
            </Column>
            {/* </Row> */}
            {/* <Row auto gap="large" span={4}> */}
            <Column>
              <Detail icon={<ShieldIcon />} title="Protect What Matters Most">
                Blockchain adoption has been slowed by inflexible, expensive, and congested public
                blockchains, or private blockchains with centralized ownership and questionable security.
                Through Interchain, a Dragonchain technology innovation, users can retain complete control of
                their data, keeping sensitive business logic and smart contracts proprietary. Interchain
                eliminates reliance on a single blockchain, embodying the decentralized vision blockchain was
                designed around.
              </Detail>
            </Column>
            <Column>
              <Detail icon={<BlockIcon />} title="Tap into Any Blockchain">
                Interchain grants the ability to leverage resources from the best in the blockchain ecosystem.
                Users can leverage features from a growing list of blockchains that includes Ethereum,
                Bitcoin, Hyperledger, and NEO, among others. Interchain represents freedom from the confines
                of a single choice—a single blockchain, and opens the doors to the potential of the entire
                network.
              </Detail>
            </Column>
            <Column>
              <Detail icon={<BubbleIcon />} title="Take on Industry Challenges">
                Dragonchain’s Interchain takes blockchain capabilities further than ever before. It takes
                existing IT infrastructures and enhances them with new levels of flexibility, security, and
                granular control over data and business logic. There has never been a better time to harness
                the benefits of blockchain.
              </Detail>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Home;
